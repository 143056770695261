import React from "react";
import './App.css';
import RoutesApp from './RoutesApp';
import { BrowserRouter } from "react-router-dom";
import { AuthContext } from './context/AuthProvider';

import FooterMenu from './layouts/FooterMenu';
import Drawer from './layouts/Drawer';
import { localStorageKey } from './constants/LocalStorageKey';
import CircularProgress from '@mui/material/CircularProgress';
import { WithAxios } from "./axios-api-kit/api-kit";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import axiosService from "./axios-api-kit/axiosService";
import { appTheme } from "./theme/colorTheme";
import { addListener, launch } from 'devtools-detector';
import { useDispatch } from 'react-redux';
import { setUserInfo, setCommonInfo, setHelpLineWhatsAppNumber, setCommonHelpline, setWithdrawalStatus, setNewsData } from "./utils/appSlice";
import runOneSignal from "./OneSignal";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function App() {
  const [currentPageName, setCurrentPageName] = React.useState('')
  const dispatchAction = useDispatch();
  const intialAppDataState = {
    isLoading: true,
    apiToken: null,
    notification: false,
    notificationText: 'Something went wrong!',
    createIdData: null,
    paymentData: null,
    depositOrWithdrawIdData: null,
    isDevToolOpen:false
  };

  const setCommonInfoFn = () => {
    axiosService.getCommonConfigs().then((response => {
      dispatchAction(setCommonInfo(response?.data?.common_data[0]?.value));
      dispatchAction(setCommonHelpline(response?.data?.helpline_data[0]?.value));
      dispatchAction(setWithdrawalStatus(response?.data?.withdraw_data[0]?.value));
      dispatchAction(setNewsData(response?.data?.news_data[0]?.value));
    })).catch((error) => {
      
    })
  };

  const getUser = React.useCallback((userObj) => {
    if(!userObj){
      axiosService.getUser().then((response) => {
        dispatchAction(setUserInfo(response?.data));
      }).catch((error) => {
      })
    }else{
      dispatchAction(setUserInfo(userObj));
    }
  }, []);

  const setCommonHelplineInfo = () => {
    axiosService.getHelpConfigs().then((data => {
      dispatchAction(setHelpLineWhatsAppNumber(data?.data))
    })).catch((error) => {
      
    })
  }

 

  const loginReducer = (prevState, action) => {
    switch (action.type) {
      case 'RETRIEVE_TOKEN':
        return {
          ...prevState,
          apiToken: action.token,
          isLoading: false,
          notification: false,
        };
      case 'LOGIN':
        return {
          ...prevState,
          apiToken: action.token,
          isLoading: false,
          notification: false,
        };
      case 'LOGOUT':
        return {
          ...prevState,
          apiToken: null,
          isLoading: false,
          notification: false,
        };
      case 'SIGNUP':
        return {
          ...prevState,
          apiToken: action.token,
          isLoading: false,
          notification: false,
        };
      case 'LOADING':
        return {
          ...prevState,
          isLoading: action.loading,
        };
      case 'NOTIFICATION':
        return {
          ...prevState,
          notification: action.notification,
          notificationText: action.notificationText
        };
      case 'DEVTOOLEVENT':
        return {
          ...prevState,
          isDevToolOpen:action.isDevtoolOpen
        };
      default: return {
        ...prevState
      }
    }
  };
   
  const [appDataState, dispatch] = React.useReducer(loginReducer, intialAppDataState);
  const authContext = React.useMemo(() => {
    return {
      signIn: (foundUser) => {
        const api_token = foundUser.api_token;
        try {
          localStorage.setItem(localStorageKey.APITOKEN, api_token);
          getUser(foundUser);
          setCommonInfoFn();
        } catch (e) {
        }
        dispatch({ type: 'LOGIN', token: api_token });
      },
      signUp: (foundUser) => {
        const api_token = foundUser.api_token;
        try {
          localStorage.setItem(localStorageKey.APITOKEN, api_token);
          getUser(foundUser);
          setCommonInfoFn();
        } catch (e) {
        }
        dispatch({ type: 'SIGNUP', token: api_token });
      },
      signOut: () => {
        try {
          const app_push_id = localStorage.getItem(localStorageKey.PUSHID);
          localStorage.clear();
          sessionStorage.clear();
          !!app_push_id && localStorage.setItem(localStorageKey.PUSHID, app_push_id);
          runOneSignal();
        } catch (e) {
        }
        dispatch({ type: 'LOGOUT' });
      },
      loadingState: (loadingState) => {
        dispatch({ type: 'LOADING', loading: loadingState });
      },
      showNotification: (isError, message) => {
        dispatch({ type: 'NOTIFICATION', notification: isError, notificationText: !!message ? message : 'Something went wrong!' });
      },
      setCreateIdData: (createIdData) => {
        localStorage.setItem(localStorageKey.SETCREATEIDDATA, JSON.stringify(createIdData));
      },
      setPaymentData: (paymentData) => {
        localStorage.setItem(localStorageKey.SETPAYMENTDATA, JSON.stringify(paymentData));
      },
      setDepositOrWithdrawIdData: (depositOrWithdrawIdData) => {
        localStorage.setItem(localStorageKey.SETDEPOSITORWITHDRAWIDDATA, JSON.stringify(depositOrWithdrawIdData));
      },
      getCreateIdState: () => {
        return JSON.parse(localStorage.getItem(localStorageKey.SETCREATEIDDATA));
      },
      getPaymentData: () => {
        return JSON.parse(localStorage.getItem(localStorageKey.SETPAYMENTDATA));
      },
      getDepositOrWithdrawIdData: () => {
        return JSON.parse(localStorage.getItem(localStorageKey.SETDEPOSITORWITHDRAWIDDATA));
      },
      getUpdatedUser: () => {
        getUser();
      },
      setCurrentPageName: () => {
        setCurrentPageName(window.location.pathname);
      },
      getDevToolState:() => {
        return appDataState.isDevToolOpen;
      }
    };
  }, [appDataState.isDevToolOpen]);

  React.useEffect(() => {
    runOneSignal();
    setCommonHelplineInfo();
    //devtools event capture start here
    const view = document.createElement('div');
    document.body.appendChild(view);
    addListener(isOpen => {
      if (isOpen) {
        localStorage.setItem(localStorageKey.ISDEVTOOLOPEN, JSON.stringify(true))
        dispatch({ type: 'DEVTOOLEVENT', isDevtoolOpen: true });
      } else {
        localStorage.setItem(localStorageKey.ISDEVTOOLOPEN, JSON.stringify(false))
        dispatch({ type: 'DEVTOOLEVENT', isDevtoolOpen: false });
      }
    }
    );
    launch();
 //devtools event capture end here 
    
    let api_token = null;
    try {
      api_token = localStorage.getItem(localStorageKey.APITOKEN);
      if(api_token){
        getUser(); 
        setCommonInfoFn(); 
      } 
    } catch (e) {
    }
    dispatch({ type: 'RETRIEVE_TOKEN', token: api_token });
  }, []);

  return (
    
      <div className="App">
      {appDataState.isLoading && <Backdrop
        sx={{ color: appTheme.color1, zIndex: (theme) => theme.zIndex.drawer + 99999999 }}
        open={true}
      >
        <CircularProgress sx={{color:`${appTheme.color12}`}} />
      </Backdrop>}
      <Snackbar open={appDataState.notification} autoHideDuration={5000} onClose={() => {
        dispatch({ type: 'NOTIFICATION', notification: false });
      }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert severity="error" onClose={() => {
          dispatch({ type: 'NOTIFICATION', notification: false });
        }}>
          {appDataState.notificationText}
        </Alert>
      </Snackbar>
      <AuthContext.Provider value={authContext}>
        <WithAxios>
          <div style={{ maxWidth: currentPageName === '/' ? '1000px':'600px', margin: '0 auto',}}>
          <BrowserRouter>
            {!!appDataState.apiToken ? <Drawer /> : ""}
            <RoutesApp />
            {!!appDataState.apiToken ? <FooterMenu /> : ""}
          </BrowserRouter>
          </div>
         
          
        </WithAxios>

      </AuthContext.Provider>
    </div>
  );
}

export default App;
